<template>
  <CChartLine
      :datasets="countDataSets"
      :options="graphOptions"
      :labels="labels"
  />
</template>

<script>
import {apiCall} from "../../../common/utils";
import moment from "moment";
import { CChartLine } from '@coreui/vue-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils/src'
function random (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}



function getColor(cd) {
  switch(cd){
    case 'WEB':
      return getStyle('success2') || '#4dbd74';
    case 'XCOST':
      return getStyle('danger') || '#e88d14';
    case 'XPIXEL':
      return getStyle('warning') || '#bda856';

  }

}


export default {
  name: 'MemberStatGraph',
  components: {
    CChartLine
  },
  props: {
    fromDay : {type: Number | Object},
  },
  data: function () {
    return {

      show: true,
      // labels: [],
      countDataSets: [],
      ersStatus: [],
      custCodeMap: {},
      agentStatusTimer: null,
      ersStatusTimer: null,

    }
  },
  async created(){
    console.log( "____________________ ErsGraph created  -------------" );
    try{
      this.countDataSets = await this.getMemberCountData();
      // console.log( "created-------> getMemberCountData:", this.countDataSets )
    }catch(err){
      console.log(err);
    }


    // console.log( ">>>>>>>>>>>>>>>>>>> this.$socket : ", this.$socket );

  },
  computed: {
    graphOptions(){
      return {
        maintainAspectRatio: false,
        legend: {
          display: true
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: true
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              stepSize: 10,
              max: 100
            },
            gridLines: {
              display: true
            }
          }]
        },
        elements: {
          point: {
            radius: 2,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        }
      }
    },
    labels(){
      // return ['1','2','3'];
      console.log("------- fromDay--------->",this.fromDay);
      let limit = Number(this.fromDay);
      let currDt = moment().subtract(limit, 'days');
      let i = 0;
      let ret = [];
      let dayStr = '';
      for(i=0; i<limit; i++){
        // console.log( "i ---------->", i );
        dayStr = currDt.add(1, 'days').format('MM/DD');
        ret.push(dayStr);
      }

      // console.log( "days===>", ret);
      return ret;
    },

  },

  methods:{

    async getMemberCountData(){
      let ret = [];
      try{
        const {result} = await apiCall('get', `/admin/api/dashboard/member-count-by-day/${this.fromDay}`);
        console.log( result );

        for( let cd in result){
          let r = result[cd];
          ret.push(
              {
                label: r.name,
                backgroundColor: hexToRgba( getColor(cd), 10),
                borderColor: getColor(cd),
                pointHoverBackgroundColor: getColor(cd),
                borderWidth: 2,
                data: r.arr
              },
          );
        }
        return ret;

      }catch(err){
        console.error(err);
      }

    },
    async getCustCode(){
      try{
        const r = await apiCall('get', `/api/customer`);
        r.result.map(i=>{
          this.custCodeMap[i.custCode] = i.custName;
        });

      }catch(err){
        console.log( err );
      }
    },


    async getStatusList(){
      try{

        const ret = {};
        for( let cd in this.custCodeMap ){
          ret[cd] = {
            name:this.custCodeMap[cd],
            total: 0,
            NA: 0, AA: 0, AN: 0, ND: 0, DP: 0, AR: 0, TS: 0, TE: 0, CB: 0, CA: 0
          }
        }
        const rs = await apiCall('get', `/api/member/dashboard/member-status`);
        // console.log( "getErsList-------------->",  rs );

        for( let r of rs.result){
          // console.log( r );
          if(!r.taskStatus) continue;
          if( !this.custCodeMap[r.custCode]) continue;

          let count = ret[r.custCode][r.taskStatus];
          count++;
          ret[r.custCode][r.taskStatus] = count;
          ret[r.custCode][r.taskStatus]++;
          ret[r.custCode].total++;

        }
        this.ersStatus = [];
        for( let key in ret ){
          this.ersStatus.push( ret[key] );
        }

        // console.log( this.ersStatus );

      }catch(err){
        console.log( err );
      }

    },

    color (value) {
      let $color
      if (value <= 25) {
        $color = 'danger'
      } else if (value > 25 && value <= 50) {
        $color = 'warning'
      } else if (value > 50 && value <= 75) {
        $color = 'success'
      } else if (value > 75 && value <= 100) {
        $color = 'info'
      }
      return $color
    },



  }
}
</script>
