<template>
    <div>
      <BRow>
        <BCol class="mb-3">

          <BCard>
            <div slot="header">
              <BIconFileEarmarkBarGraphFill/> <strong> 회원등록 현황</strong>
              <div class="card-header-actions">
              </div>
            </div>
            <MemberStatGraph :from-day="90"></MemberStatGraph>
          </BCard>


          <BCard>
            <div slot="header">
              <BIconCreditCardFill/> <strong> 제품 판매 현황 (신규/기존)</strong>
              <div class="card-header-actions" sm="2">
                <BInputGroupAppend>
                  <date-picker 
                    v-model="time1" 
                    type="month"
                    format="YYYY-MM"
                    :clearable="false"
                    :default-value="new Date()"
                  />
                </BInputGroupAppend>
              </div>
            </div>
            <ProductOrderGraph
              :baseDate="prdDate"
            ></ProductOrderGraph>
          </BCard>


          <BCard>
            <div slot="header">
              <BIconFileEarmarkBarGraphFill/> <strong> Xcost 로그인 현황</strong>
              <div class="card-header-actions">
              </div>
            </div>

            <BRow>
              <BCol sm="2" class="text-right">
                <BButton>
                  <Strong>일반</Strong>
                  <BBadge variant="info" class="ml-2">{{stat['20'].total}}</BBadge>
                </BButton>
              </BCol>
                <BCol>
                  <b-progress height="2rem" :max="stat['20'].total" striped animated show-value>
                    <b-progress-bar :value="stat['20'].logOn" variant="success"></b-progress-bar>
                    <b-progress-bar :value="stat['20'].logOff" variant="dark"></b-progress-bar>
                  </b-progress>
                </BCol>

            </BRow>
            <BRow class="mt-2">
              <BCol sm="2" class="text-right">
                <BButton variant="info">
                  <Strong>프리미엄-I</Strong>
                  <BBadge variant="warning" class="ml-2">{{stat['P1'].total}}</BBadge></BButton>
              </BCol>
              <BCol>
                <b-progress height="2rem" :max="stat['P1'].total" striped animated show-value>
                  <b-progress-bar :value="stat['P1'].logOn" variant="primary"></b-progress-bar>
                  <b-progress-bar :value="stat['P1'].logOff" variant="dark"></b-progress-bar>
                </b-progress>
              </BCol>

            </BRow>
            <BRow class="mt-2" v-if="stat['P2'].total!==0">
              <BCol sm="2" class="text-right">
                <BButton variant="danger">
                  <Strong>프리미엄-II</Strong>
                  <BBadge variant="primary" class="ml-2">{{stat['P2'].total}}</BBadge></BButton>
              </BCol>
              <BCol>
                <b-progress height="2rem" :max="stat['P2'].total" striped animated show-value>
                  <b-progress-bar :value="stat['P2'].logOn" variant="warning"></b-progress-bar>
                  <b-progress-bar :value="stat['P2'].logOff" variant="dark"></b-progress-bar>
                </b-progress>
              </BCol>
            </BRow>
          </BCard>


          <BCard>

            <div slot="header">
              <BIconFileEarmarkBarGraphFill/> <strong> 원클릭견적 발송건수 현황</strong>
              <div class="card-header-actions">
              </div>
            </div>

            <BRow>
              <BCol sm="2" class="text-right">
                <BButton variant="info">
                  <Strong>전체</Strong>
                  <BBadge variant="warning" class="ml-2">{{qouteStatus.allDay.allCnt}}</BBadge>
                </BButton>
              </BCol>
              <BCol>
                <b-progress height="2rem" :max="qouteStatus.allDay.allCnt" striped animated show-value>
                  <b-progress-bar :value="qouteStatus.allDay.successCnt" variant="success" title="발송성공"></b-progress-bar>
                  <b-progress-bar :value="qouteStatus.allDay.failCnt" variant="warning" title="발송실패"></b-progress-bar>
                  <b-progress-bar :value="qouteStatus.allDay.skipCnt" variant="dark" title="발송생략"></b-progress-bar>
                </b-progress>
              </BCol>
            </BRow>

            <BRow class="mt-2">
              <BCol sm="2" class="text-right">
                <BButton variant="info">
                  <Strong>오늘</Strong>
                  <BBadge variant="light" class="ml-2">{{qouteStatus.toDay.allCnt}}</BBadge>
                </BButton>
              </BCol>
              <BCol>
                <b-progress height="2rem" :max="qouteStatus.toDay.allCnt" striped animated show-value>
                  <b-progress-bar :value="qouteStatus.toDay.successCnt" variant="success" title="발송성공"></b-progress-bar>
                  <b-progress-bar :value="qouteStatus.toDay.failCnt" variant="warning" title="발송실패"></b-progress-bar>
                  <b-progress-bar :value="qouteStatus.toDay.skipCnt" variant="dark" title="발송생략"></b-progress-bar>
                </b-progress>
              </BCol>
            </BRow>

          </BCard>

          <BCardGroup>
            <BCard style="height:300px">
              <div slot="header">
                <BIconPieChart/> <strong> 마스터 정보</strong>
                <div class="card-header-actions">
                </div>
              </div>
              <BRow>
                <BCol>
                  <BButton block to="/product/info"  variant="info">제품 정보관리</BButton>
                  <BButton block to="/product/key"   variant="info">제품 키관리</BButton>
                  <BButton block to="/product/token" variant="info">인증 토큰관리</BButton>
                </BCol>
              </BRow>
            </BCard>

            <BCard>
              <div slot="header">
                <BIconBarChart/> <strong>판매관리</strong>
                <div class="card-header-actions">
                </div>
              </div>
                <BRow>
                  <BCol>
                    <BButton block to="/sales/order" variant="primary">상품 주문 관리</BButton>
                    <BButton block to="/sales/product" variant="primary">제품 주문 관리</BButton>
                    <BButton block to="/sales/upload" variant="primary">주문데이터 업로드</BButton>
                    <BButton block to="/sales/tax" variant="primary">세금 계산서</BButton>
                  </BCol>
                </BRow>
            </BCard>
          </BCardGroup>

        </BCol>

      </BRow>


    </div>


</template>
<script>
    import {apiCall, cloneVar, getQueryString, qmcToName} from '../../common/utils';
    // import AgentStatusChart from './dashCharts/AgentStatusChart'
    // import SnmpMessageChart from './dashCharts/SNMPChart'
    // import SnmpCountChart from './dashCharts/SNMPCountChart'
    // import multiBarChart from '../src/views/charts/CChartBarSimple';
    import moment from "moment";

    import MemberStatGraph from './components/MemberStatGraph';
    import ProductOrderGraph from './components/ProductOrderGraph';

    // Date Picker ===
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/ko';
    // ================


  export default {
    name: 'Dashboard',
    components: {
      MemberStatGraph,
      ProductOrderGraph,
      DatePicker
    },
    data () {
      return {
        stat : {
          '20': { logOn: 0, logOff: 0, total: 0 },
          'P1': { logOn: 0, logOff: 0, total: 0 },
          'P2': { logOn: 0, logOff: 0, total: 0 }
        },
        statInterval: 0,
        qouteStatus: {
          allDay: {allCnt: 0, successCnt: 0, failCnt: 0, skipCnt: 0},
          toDay: {allCnt: 0, successCnt: 0, failCnt: 0, skipCnt: 0},
        },
        prdDate: `${moment().locale('ko').format('YYYY-MM')}`,
        time1: new Date(),
      }
    },

    watch: {
      time1: "prdSelectDate"
    },

    computed: {

    },

    async created(){
      this.$parent.$parent.reRenderSideBar();
      this.$parent.$parent.reRenderHeader();

      console.log( ">>>>>>>>>>>>>>>>>>> Dashboard created  -------------" );
      // console.log( ">>>>>>>>>>>>>>>>>>> this.$socket : ", this.$socket );
      try{
        await this.getLoginStatus();
        await this.getQuoteSendStatus();
        this.statInterval = setInterval( async ()=>{
          await this.getLoginStatus();
          await this.getQuoteSendStatus();
        }, 60*1000)
      }catch(err){

      }
    },

    methods: {
      prdSelectDate() {
        const r = moment(this.time1).locale('ko').format('YYYY-MM');
        this.prdDate = `${r}`;
      },

      currentDateTime() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
      async getLoginStatus(){
        const {result} = await apiCall('get', `/admin/api/dashboard/login-status`);
        console.log( "getLoginStatus ------------------>", this.stat );
        this.stat = result;

      },
      color (value) {
        let $color
        if (value <= 25) {
          $color = 'info'
        } else if (value > 25 && value <= 50) {
          $color = 'success'
        } else if (value > 50 && value <= 75) {
          $color = 'warning'
        } else if (value > 75 && value <= 100) {
          $color = 'danger'
        }
        return $color
      },

      async getQuoteSendStatus() {
        const {result} = await apiCall('get', `/admin/api/dashboard/quote-send-status`);
        console.log("result", result);
        this.qouteStatus = result;
        console.log("this.qouteStatus", this.qouteStatus);
      }

    },
    mounted() {

    },
    beforeDestroy() {
      console.log('--------------- dashboard: beforeDestroy( ) cleared Interval ---------------: ');
      clearInterval(this.statInterval);
      // clearInterval(this.agentOffCountTimer);
      // clearInterval(this.snmpChartTimer);
      // clearInterval(this.snmpCountTimer);
    }
  }

</script>

